import React, { useEffect, useState } from 'react'
import { Card, ListGroup, Spinner, Alert } from 'react-bootstrap'
import { database, settings } from '../Firebase'
export default function AdminReport() {

  const [isLoading, setIsLoading] = useState(false);
  const [isRouteEnabled, setIsRouteEnabled] = useState(false);
  const [error, setError] = useState(null);

  const [config, setConfig] = useState({
    adminReport: false,
    beerExchangeCount: 0,
    cocktailExchangeCount: 0,
    totalGuest: 0
  })

  const [reserved, setReserved] = useState({
    beer: 0,
    beerCount: 0,
    cocktail: 0,
    cocktailCount: 0,
    available: 0,
    cancelled: 0,
    actives: 0
  })

  const [selected, setSelected] = useState({
    beer: 0,
    cocktail: 0,
    total: 0
  })

  useEffect(() => {
    //setIsLoading(true);

    const validateRoute = async () => {
      setIsLoading(true);
      try {
        settings.lachelaSettings.doc('event').onSnapshot((doc) => {
          const _settings = doc.data();
          if (_settings && _settings.adminReport) {
            setConfig({
              adminReport: _settings.adminReport,
              beerExchangeCount: _settings.beerExchangeCount,
              cocktailExchangeCount: _settings.cocktailExchangeCount,
              totalGuest: _settings.totalGuest,
            });
            setIsRouteEnabled(true);
            generateReport(_settings);
          } else {
            setError('Ruta no habilitada.');
            setIsLoading(false);
          }
        })
      } catch (err) {
        setError('Error al validar la ruta: ' + err);
      }
      setIsLoading(false);
    };

    const generateReport = async (config) => {
      setIsLoading(true);
      try {
        database.lachelaCodes.onSnapshot((docs) => {
          //RESERVED
          let reservedBeer = 0;
          let reservedBeerCount = 0;
          let reservedCocktail = 0;
          let reservedCocktailCount = 0;
          let confirmationPending = 0;
          let cancelled = 0;
          let actives = 0;

          //SELECTED
          let redemeedBeer = 0;
          let redemeedCocktail = 0;

          docs.forEach((doc) => {
            const user = doc.data();
            if (user.drink?.toLowerCase() === 'cerveza') {
              reservedBeer++;
              reservedBeerCount = reservedBeer * config.beerExchangeCount;
              redemeedBeer += user.counter;
            } else if (user.drink?.toLowerCase() === 'cocktail') {
              reservedCocktail++;
              reservedCocktailCount = reservedCocktail * config.cocktailExchangeCount;
              redemeedCocktail += user.counter;
            }

            if (user.status === 'cancelled') {
              cancelled++;
            }

            if (user.status === 'active') {
              actives++;
            }
          });

          confirmationPending = config.totalGuest - (reservedBeer + reservedCocktail + cancelled);

          setReserved({
            beer: reservedBeer,
            cocktail: reservedCocktail,
            beerCount: reservedBeerCount,
            cocktailCount: reservedCocktailCount,
            available: confirmationPending,
            cancelled: cancelled,
            actives: actives
          });

          setSelected({
            beer: redemeedBeer,
            cocktail: redemeedCocktail,
            total: redemeedBeer + redemeedCocktail
          });

          setIsLoading(false);
        });
      } catch (err) {
        setError('Error al generar reporte: ' + err);
      }
      setIsLoading(false);
    };

    validateRoute();
  }, []);


  return (
    <div
      className="responsive-container"
      style={{
        position: 'relative',
        width: '100vw',
        height: '100vh',
        display: 'flex',
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '30px',
      }}
    >
      <style>{`
        @media (max-width: 576px) {
          .responsive-container {
            background-image: url('../../../mobilepe.svg');
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
          }
          .button-group {
            flex-direction: column;
          }
          .button-group .btn {
            width: 100%;
            margin-bottom: 10px;
          }
        }

        
        @media (min-width: 577px) and (max-width: 1024px) {
      
          @media (orientation: portrait) {
            .responsive-container {
              background-image: url('../../../tabletpe.svg');
              background-size: cover;
              background-position: center;
              background-repeat: no-repeat;
            }
          }

   
          @media (orientation: landscape) {
            .responsive-container {
              background-image: url('../../../tabletpe.svg');
              background-size: cover;
              background-position: center;
              background-repeat: no-repeat;
            }
          }
          
          .button-group .btn {
            width: 48%;
          }
        }

      
        @media only screen and (min-device-width: 820px) and (max-device-width: 1180px) {
    
          @media (orientation: portrait) {
            .responsive-container {
              background-image: url('../../../tabletpe.svg');
              background-size: cover;
              background-position: center;
              background-repeat: no-repeat;
            }
          }

       
          @media (orientation: landscape) {
            .responsive-container {
              background-image: url('../../../tabletpe.svg');
              background-size: cover;
              background-position: center;
              background-repeat: no-repeat;
            }
          }
        }

        @media (min-width: 1025px) {
          .responsive-container {
            background-image: url('../../../backgroundpe.svg');
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
          }
          .button-group {
            flex-direction: row;
          }
          .button-group .btn {
            width: 49%;
          }
        }
      `}</style>
      <Card
        style={{
          padding: '30px',
          width: '100%',
          maxWidth: '560px',
          maxHeight: '100%',
          height: "auto",
          borderRadius: '12px',
          boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)',
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
        }}>
        {error && <Alert variant="danger" className="mt-3">{error}</Alert>}
        {
          isRouteEnabled ?
            <>
              {
                !isLoading ?
                  <>
                    <Card style={{ width: '100%', backgroundColor: "#fbfbfb", marginTop: 15 }}>
                      <Card.Header>INVITADOS CONFIRMADOS</Card.Header>
                      <ListGroup variant="flush">
                        <ListGroup.Item>
                          <span> Cervezas : </span>
                          <span style={{ color: "#008aff", fontWeight: 500 }}> {reserved.beer} personas - </span>
                          <span style={{ color: "#008aff", fontWeight: 600 }}> ({reserved.beerCount} bebidas)</span>
                        </ListGroup.Item>
                        <ListGroup.Item>
                          <span> Cocktails:</span>
                          <span style={{ color: "#008aff", fontWeight: 500 }}> {reserved.cocktail} personas - </span>
                          <span style={{ color: "#008aff", fontWeight: 600 }}> ({reserved.cocktailCount} bebidas)</span>
                        </ListGroup.Item>
                        <ListGroup.Item>
                          <span> Total confirmados:</span>
                          <span style={{ color: "#008aff", fontWeight: 600 }}> {reserved.beer + reserved.cocktail} personas</span>
                        </ListGroup.Item>
                        <ListGroup.Item>
                          <span> Total cancelados:</span>
                          <span style={{ color: "#008aff", fontWeight: 600 }}> {reserved.cancelled} personas</span>
                        </ListGroup.Item>
                        <ListGroup.Item>
                          <span> PENDIENTES:</span>
                          <span style={{ color: "#008aff", fontWeight: 600 }}> {reserved.available} personas</span>
                        </ListGroup.Item>
                        <ListGroup.Item>
                          <span> CHECKED-IN (ACTIVES): :</span>
                          <span style={{ color: "#008aff", fontWeight: 600 }}> {reserved.actives} personas</span>
                        </ListGroup.Item>
                      </ListGroup>
                    </Card>
                    <Card style={{ width: '100%', backgroundColor: "#fbfbfb", marginTop: 15 }}>
                      <Card.Header>BEBIDAS CANJEADAS</Card.Header>
                      <ListGroup variant="flush">
                        <ListGroup.Item>
                          <span> Total cervezas:</span>
                          <span style={{ color: "#008aff", fontWeight: 500 }}> {selected.beer}</span>
                        </ListGroup.Item>
                        <ListGroup.Item>
                          <span> Total cocktails:</span>
                          <span style={{ color: "#008aff", fontWeight: 500 }}> {selected.cocktail}</span>
                        </ListGroup.Item>
                        <ListGroup.Item>
                          <span> TOTAL BEBIDAS:</span>
                          <span style={{ color: "#008aff", fontWeight: 600 }}> {selected.total}</span>
                        </ListGroup.Item>
                      </ListGroup>
                    </Card>
                  </> :
                  <></>
              }
            </> :
            <>
              <Spinner animation="border" variant="primary" className="mt-3" />
            </>
        }
      </Card>
    </div>
  );
}
