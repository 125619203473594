import firebase from "firebase/app"
import "firebase/auth"
import 'firebase/database'
import 'firebase/firestore'

const app = firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
})

const firestore = app.firestore()

// export const googleProvider = new firebase.auth.GoogleAuthProvider();
// export const auth = app.auth()
export const database = {
  lachelaCodes: firestore.collection(process.env.REACT_APP_FIREBASE_COLLECTION_NAME),
}

export const settings = {
  lachelaSettings: firestore.collection("settings"),
}

export default app