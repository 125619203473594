import React, { useEffect, useState } from 'react'
import { Card, ListGroup, Spinner } from 'react-bootstrap'
import { database } from '../Firebase'
export default function GuestReport() {

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({
    total: 0
  })

  const [reserved, setReserved] = useState({
    beer: 0,
    beerCount: 0,
    cocktail: 0,
    cocktailCount: 0,
    available: 0
  })

  const [selected, setSelected] = useState({
    beer: 0,
    cocktail: 0,
    total: 0
  })

  useEffect(() => {
    setIsLoading(true);
  
    database.lachelaCodes.onSnapshot((docs) => {

      let totalBeer = 0;
      let totalCocktail = 0;
      let reservedBeer = 0;
      let reservedCocktail = 0;
      let available = 0;
  
      docs.forEach((doc) => {
        const user = doc.data();
       
        
        if (user.drink?.toLowerCase() === 'cerveza') {
          totalBeer += user.counter;
          reservedBeer++;
        } else if (user.drink?.toLowerCase() === 'cocktail') {
          totalCocktail += user.counter;
          reservedCocktail++;
        }
  
        
        if (user.status === 'registered' && user.counter === 0) {
          available++;
        }
      });
  
      setSelected({
        beer: totalBeer,
        cocktail: totalCocktail,
        total: totalBeer + totalCocktail
      });
  
      setReserved({
        beer: reservedBeer,
        cocktail: reservedCocktail,
        beerCount: reservedBeer,
        cocktailCount: reservedCocktail,
        available: available
      });
  
      setIsLoading(false);
    });
  }, []);
  

  return (
    <div
      className="responsive-container"
      style={{
        position: 'relative',
        width: '100vw',
        height: '100vh',
        display: 'flex',
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '30px',
      }}
    >
      <style>{`
        @media (max-width: 576px) {
          .responsive-container {
            background-image: url('../../../mobilepe.svg');
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
          }
          .button-group {
            flex-direction: column;
          }
          .button-group .btn {
            width: 100%;
            margin-bottom: 10px;
          }
        }

        
        @media (min-width: 577px) and (max-width: 1024px) {
      
          @media (orientation: portrait) {
            .responsive-container {
              background-image: url('../../../tabletpe.svg');
              background-size: cover;
              background-position: center;
              background-repeat: no-repeat;
            }
          }

   
          @media (orientation: landscape) {
            .responsive-container {
              background-image: url('../../../tabletpe.svg');
              background-size: cover;
              background-position: center;
              background-repeat: no-repeat;
            }
          }
          
          .button-group .btn {
            width: 48%;
          }
        }

      
        @media only screen and (min-device-width: 820px) and (max-device-width: 1180px) {
    
          @media (orientation: portrait) {
            .responsive-container {
              background-image: url('../../../tabletpe.svg');
              background-size: cover;
              background-position: center;
              background-repeat: no-repeat;
            }
          }

       
          @media (orientation: landscape) {
            .responsive-container {
              background-image: url('../../../tabletpe.svg');
              background-size: cover;
              background-position: center;
              background-repeat: no-repeat;
            }
          }
        }

        @media (min-width: 1025px) {
          .responsive-container {
            background-image: url('../../../backgroundpe.svg');
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
          }
          .button-group {
            flex-direction: row;
          }
          .button-group .btn {
            width: 49%;
          }
        }
      `}</style>
      <Card
        style={{
          padding: '30px',
          width: '100%',
          maxWidth: '560px',
          maxHeight: '100%',
          height: "auto",
          borderRadius: '12px',
          boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)',
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
        }}>
        {
          !isLoading ?
            <>
              
              <Card style={{ width: '100%', backgroundColor: "#fbfbfb", marginTop: 15 }}>
                <Card.Header>BEBIDAS CANJEADAS</Card.Header>
                <ListGroup variant="flush">
                  <ListGroup.Item>
                    <span> Total cervezas:</span>
                    <span style={{ color: "#008aff", fontWeight: 500 }}> {selected.beer}</span>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <span> Total cocktails:</span>
                    <span style={{ color: "#008aff", fontWeight: 500 }}> {selected.cocktail}</span>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <span> TOTAL BEBIDAS:</span>
                    <span style={{ color: "#008aff", fontWeight: 600 }}> {selected.total}</span>
                  </ListGroup.Item>
                </ListGroup>
              </Card>
            </> :
            <>
              <Spinner animation="border" role="status" color='primary' />
            </>
        }
      </Card>
    </div>
  );
}
