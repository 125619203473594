import React, { useState } from 'react';
import QrReader from 'react-qr-reader';
import { Button, Container, Spinner, Card, Alert } from 'react-bootstrap';
import { database } from '../Firebase';
import NavBar from '../components/NavBar';

export default function Redeem() {
  const [delay, setDelay] = useState(500);
  const [attendance, setAttendance] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [showScanner, setShowScanner] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [drinker, setDrinker] = useState(null);
  const [showInvalidAlert, setShowInvalidAlert] = useState(false);

  function handleError(err) {
    console.error(err);
  }

  const handleScan = async (data) => {
    if (!data) return;

    const eventUrl = new URL(data);
    const searchParams = new URLSearchParams(eventUrl.search);
    const proxyKey = searchParams.get('pk');

    if (proxyKey) {
      setShowScanner(false);
      setIsLoading(true);
      database.lachelaCodes
        .doc(proxyKey)
        .get()
        .then((response) => {
          const user = response.data();
          setDrinker(user);

          if (user.status !== "active") {
            setShowInvalidAlert(true);
            setIsLoading(false);
            return;
          }

          if (user.counter < user.max) {
            const updateDocument = {
              ...user,
              counter: user.counter + 1,
              // hasCheckIn: true,
            };

            database.lachelaCodes
              .doc(response.id)
              .update(updateDocument)
              .then(() => {
                setAttendance(true);
                setIsSuccess(true);
                setIsLoading(false);
              });
          } else {
            setAttendance(true);
            setIsSuccess(false);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
        });
    }
  };

  const handleClick = () => {
    setShowScanner(true);
    setAttendance(false);
    setShowInvalidAlert(false);
  };

  return (
    <Container className="main row p-0 m-0 responsive-container" style={{ height: '100vh' }} fluid>
      <style>{`
        @media (max-width: 576px) {
          .responsive-container {
            background-image: url('../../../mobilepe.svg');
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
          }
        }
        @media (min-width: 577px) and (max-width: 1024px) {
          .responsive-container {
            background-image: url('../../../tabletpe.svg');
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
          }
        }
        @media (min-width: 1025px) {
          .responsive-container {
            background-image: url('../../../backgroundpe.svg');
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
          }
        }
      `}</style>

      <Container style={{ height: '60vh', width: '100vw' }} className="main p-0 m-0 d-flex justify-content-center align-items-center">
        <NavBar show={true} />

        <Card className="centered-card" style={{ maxWidth: 600, width: '100%', minHeight: 520, display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '20px' }}>
          <div className="content-container d-flex flex-column align-items-center" style={{ width: '100%' }}>
            {showInvalidAlert && (
              <Alert variant="danger" className="text-center w-100">
                🚫 QR inválido, verifique que haya hecho "check-in" para poder canjear su bebida.
                <div className="d-flex justify-content-center mt-2">
                  <Button
                    size="lg"
                    variant="outline-light"
                    onClick={handleClick}
                    style={{ background: '#008aff', color: 'white', border: 'none' }}
                  >
                    Escanear nuevamente
                  </Button>
                </div>
              </Alert>
            )}

            {attendance && !showScanner && !showInvalidAlert && (
              <div className="text-center w-100">
                {isSuccess ? (
                  <div className="success-message" style={{ background: '#2c6949', color: 'white', padding: '15px', borderRadius: 15, margin: '20px 0' }}>
                    🎉 QR válido para canjear {drinker.drink} {drinker.drink === 'Cerveza' ? '🍺' : '🍸'}
                  </div>
                ) : (
                  <div className="failure-message" style={{ background: '#f44336', color: 'white', padding: '15px', borderRadius: 15, margin: '20px 0' }}>
                    🚫 QR no válido para canjear {drinker.drink} {drinker.drink === 'Cerveza' ? '🍺' : '🍸'}
                  </div>
                )}
                <Button size="lg" className="retry-button" style={{ background: '#008aff', color: 'white', border: 'none' }} onClick={handleClick}>
                  Escanear nuevamente
                </Button>
              </div>
            )}

            {!attendance && showScanner && !showInvalidAlert && (
              <div className="scanner-section text-center" style={{display: "flex", flexDirection: "alignItems", flexDirection: "column",justifyContent: "center"}}>
                <h4>Escanea el QR para validar si es posible entregar la bebida</h4>
                <QrReader
                  style={{ width: '40vh', height: 'auto', margin: 'auto' }}
                  delay={delay}
                  onError={handleError}
                  onScan={handleScan}
                />
              </div>
            )}

            {isLoading && (
              <div className="loading-section text-center" style={{ marginTop: '20px' }}>
                <Spinner animation="border" role="status" color="primary" />
              </div>
            )}
          </div>
        </Card>
      </Container>
    </Container>
  );
}
