import React from 'react';
import { Button, Card } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';

export default function OrderMyGuestDrink() {
  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const email = formData.get("email").toLowerCase().trim();

    window.location.replace(`/status/${email}`);
  };

  return (
    <div
      style={{
        position: "relative",
        width: "100vw",
        height: "100vh",
        display: "flex",
        alignContent: "center",
        alignItems: "center",
        justifyContent: "center",
        backgroundImage: 'url(../../../backgroundpe.svg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: "no-repeat",
        padding: '30px'
      }}
    >
      <Card
        style={{
          padding: '30px',
          width: '100%',
          maxWidth: '560px',
          maxHeight: '100%',
          height: "auto",
          borderRadius: '12px',
          boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)',
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
        }}
      >
        <div style={{ display: "flex", maxWidth: 500, width: "100%", flexDirection: "column" }}>
          <h3 style={{ color: '#088aff', textAlign: 'center', fontSize: '1.25rem' }}>Ver Estado</h3>
          <p style={{ marginTop: 15, marginBottom: 15, textAlign: 'justify', fontSize: '.95rem' }}>
            Ingresa tu email para ver cuantas bebidas te quedan
          </p>

          <div style={{ display: "flex", flexDirection: "column" }}>
            <form onSubmit={handleSubmit} style={{ maxWidth: 400, width: "100%", margin: "auto", marginTop: 15 }}>
              <div style={{ display: "flex", width: "100%" }}>
                <Form.Control
                  type="text"
                  placeholder="Ingrese email"
                  aria-label="email"
                  name="email"
                  size='lg'
                />
              </div>
              <br />
              <div style={{ display: "flex", width: "100%" }}>
                <Button type="submit" variant="light" size='lg' style={{ width: "100%" }}>Ver</Button>
              </div>
            </form>
          </div>
        </div>
      </Card>
    </div>
  );
}
