import React, { useState, useEffect } from 'react';
import { Card, Button, Alert, Spinner } from 'react-bootstrap';
import { useParams, useHistory } from 'react-router-dom';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { database, settings } from '../Firebase';

const EventConfirm = () => {
  const [settingsEvent, setSettingsEvent] = useState(null);
  const [drink, setDrink] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);
  const [beerEnabled, setBeerEnabled] = useState(false);
  const [cocktailEnabled, setCocktailEnabled] = useState(false);
  const [userStatus, setUserStatus] = useState('loading');
  const [step, setStep] = useState(1);
  const { email } = useParams();
  const history = useHistory();

  useEffect(() => {
    const fetchSettingsAndStatus = async () => {
      settings.lachelaSettings.doc('event').onSnapshot((doc) => {
        if (doc.exists) {
          const beer = doc.data().beerEnabled;
          const cocktail = doc.data().cocktailEnabled;
          setBeerEnabled(beer);
          setCocktailEnabled(cocktail);

          setSettingsEvent(doc.data());

          if (!beer) {
            setDrink('Cocktail');
          } else if (!cocktail) {
            setDrink('Cerveza');
          }
        }
      });

      const querySnapshot = await database.lachelaCodes.where('email', '==', email).get();
      if (!querySnapshot.empty) {
        const userDoc = querySnapshot.docs[0];
        const userData = userDoc.data();
        setUserStatus(userData.status);
      } else {
        setUserStatus('not_found');
      }
    };

    fetchSettingsAndStatus();
  }, [email]);

  const handleConfirm = async () => {
    setStep(2);
  };

  const handleDecline = async () => {
    const db = firebase.firestore();
    const userRef = database.lachelaCodes;
    const querySnapshot = await userRef.where('email', '==', email).get();

    if (!querySnapshot.empty) {
      const userDoc = querySnapshot.docs[0];
      const docRef = userRef.doc(userDoc.id);
      await docRef.update({
        status: 'cancelled',
      });

      window.location.href = 'https://lu.ma/dr3edzga';
    }
  };

  const handleDrinkSubmit = async () => {
    const db = firebase.firestore();
    const userRef = database.lachelaCodes;
    const querySnapshot = await userRef.where('email', '==', email).get();

    if (!querySnapshot.empty) {
      const userDoc = querySnapshot.docs[0];
      const docRef = userRef.doc(userDoc.id);
      const maxDrinks =
        drink.toLowerCase() === 'cocktail' ? settingsEvent.cocktailExchangeCount : settingsEvent.beerExchangeCount;

      await docRef.update({
        drink: drink,
        status: 'confirmed',
        max: maxDrinks,
      });

      setShowSuccess(true);
    }
  };

  if (userStatus === 'loading') {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }

  if (userStatus !== 'registered') {
    return (
      <div className="container" style={{ maxWidth: '500px', marginTop: '20px' }}>
        <Card style={{ padding: '30px', borderRadius: '12px', boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)' }}>
          <p style={{ color: '#088aff', textAlign: 'center' }}>Ya realizaste la confirmación para el evento o la opción ya no se encuentra disponible.</p>
          <Button
            href='https://lu.ma/dr3edzga'
          >
            Ir al evento
          </Button>
        </Card>
      </div>
    );
  }

  return (

    <div
      className="responsive-container"
      style={{
        position: 'relative',
        width: '100vw',
        height: '100vh',
        display: 'flex',
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '30px',
      }}
    >
      <style>{`
        @media (max-width: 576px) {
          .responsive-container {
            background-image: url('../../../mobilepe.svg');
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
          }
          .button-group {
            flex-direction: column;
          }
          .button-group .btn {
            width: 100%;
            margin-bottom: 10px;
          }
        }

        
        @media (min-width: 577px) and (max-width: 1024px) {
      
          @media (orientation: portrait) {
            .responsive-container {
              background-image: url('../../../tabletpe.svg');
              background-size: cover;
              background-position: center;
              background-repeat: no-repeat;
            }
          }

   
          @media (orientation: landscape) {
            .responsive-container {
              background-image: url('../../../tabletpe.svg');
              background-size: cover;
              background-position: center;
              background-repeat: no-repeat;
            }
          }
          
          .button-group .btn {
            width: 48%;
          }
        }

      
        @media only screen and (min-device-width: 820px) and (max-device-width: 1180px) {
    
          @media (orientation: portrait) {
            .responsive-container {
              background-image: url('../../../tabletpe.svg');
              background-size: cover;
              background-position: center;
              background-repeat: no-repeat;
            }
          }

       
          @media (orientation: landscape) {
            .responsive-container {
              background-image: url('../../../tabletpe.svg');
              background-size: cover;
              background-position: center;
              background-repeat: no-repeat;
            }
          }
        }

        @media (min-width: 1025px) {
          .responsive-container {
            background-image: url('../../../backgroundpe.svg');
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
          }
          .button-group {
            flex-direction: row;
          }
          .button-group .btn {
            width: 49%;
          }
        }
      `}</style>

      <Card
        style={{
          padding: '30px',
          width: '100%',
          maxWidth: '560px',
          maxHeight: '100%',
          height: "auto",
          borderRadius: '12px',
          boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)',
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
        }}
      >

        {(step === 1 && !showSuccess) && (
          <>
            <h3 style={{ color: '#088aff', textAlign: 'center', fontSize: '1.25rem' }}>Confirma tu asistencia</h3>
            <p style={{ marginTop: 15, marginBottom: 15, textAlign: 'justify', fontSize: '.95rem' }}>Por favor, selecciona a continuación si confirmas o cancelas tu asistencia a La Chela de los Founders, que se llevará a cabo el próximo 08 de noviembre a las 6 PM en Selina - Miraflores.</p>
            <div className="d-flex button-group justify-content-between" style={{ marginTop: '20px' }}>
              <Button
                variant="primary"
                onClick={handleConfirm}
                style={{ backgroundColor: '#088aff', borderColor: '#088aff' }}
              >
                Confirmar asistencia
              </Button>
              <Button
                variant="outline-secondary"
                onClick={handleDecline}

              >
                No podré asistir
              </Button>
            </div>
          </>
        )}


        {(step === 2 && !showSuccess) && (
          <>
            <div style={{ marginTop: '15px', textAlign: 'center' }}>
              <h3 style={{ color: '#088aff', textAlign: 'center', fontSize: '1.25rem' }}>¡Hora de brindar!</h3>
              {beerEnabled && cocktailEnabled && (
                <div style={{ marginBottom: '15px' }}>
                  <p style={{ fontSize: ".95rem" }}>Como anfitriones del evento, queremos invitarte a las primeras rondas. Por favor, selecciona tu bebida preferida a continuación:</p>
                  <Button
                    variant={drink === 'Cerveza' ? 'primary' : 'outline-primary'}
                    onClick={() => setDrink('Cerveza')}
                    style={{ marginRight: '10px', width: '45%' }}
                  >
                    Chelas &#127866;
                  </Button>
                  <Button
                    variant={drink === 'Cocktail' ? 'primary' : 'outline-primary'}
                    onClick={() => setDrink('Cocktail')}
                    style={{ width: '45%' }}
                  >
                    Cocktails &#127865;
                  </Button>
                </div>
              )}
              {!beerEnabled || !cocktailEnabled ? (
                <div style={{ marginBottom: '15px' }}>
                  <p style={{ fontSize: ".95rem" }}>Como anfitriones del evento, queremos invitarte las primeras rondas.</p>
                  <p style={{ fontWeight: 600 }}>
                    Por asistir al evento tendrás {beerEnabled ? 'cerveza' : 'cocktail'} de cortesía.
                  </p>
                </div>
              ) : null}
            </div>

            <div className="d-flex justify-content-center" style={{ marginTop: '20px' }}>
              <Button
                variant="primary"
                onClick={handleDrinkSubmit}
                style={{ backgroundColor: '#088aff', borderColor: '#088aff', width: '100%' }}
                disabled={showSuccess}
              >
                Confirmar
              </Button>
            </div>
          </>
        )}

        {showSuccess && (
          <div>
            <Alert variant="success" className="mt-3">
              <h4 style={{ color: "#088aff", align: "center" }}>¡Gracias por confirmar tu asistencia!</h4>
              Te esperamos el viernes 8, en Selina Miraflores a las 6 PM para brindar por las nuevas conexiones.
            </Alert>
            <p style={{ fontWeight: 600 }}>Dirección: Calle Alcanfores 465, Miraflores 15074, Peru</p>
            <div style={{ display: "flex" }}>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d243.80188422833487!2d-77.02797608810506!3d-12.12377136685647!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9105c81958b7d7df%3A0x45248ec993093c21!2sSelina%20Miraflores%20Lima!5e0!3m2!1ses-419!2spe!4v1730918612712!5m2!1ses-419!2spe"
                width="100%" height="300"
                allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade">
              </iframe>
            </div>
          </div>
        )}
      </Card>
    </div>
  );
};

export default EventConfirm;
