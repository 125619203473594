import React, { useState, useEffect } from 'react';
import { Form, Button, Alert, Card, Spinner, ToggleButtonGroup, ToggleButton, Tabs, Tab } from 'react-bootstrap';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { database, settings } from '../Firebase';
import QrReader from 'react-qr-reader';

const GuestAdmin = () => {
  const [email, setEmail] = useState('');
  const [qrData, setQrData] = useState(null);
  const [drinkIncrement, setDrinkIncrement] = useState(1);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [isRouteEnabled, setIsRouteEnabled] = useState(false);
  const [guestData, setGuestData] = useState(null);
  const [loading, setLoading] = useState(false);

  const [activeTab, setActiveTab] = useState('email');

  const maxIncrement = 5;
  const minIncrement = 1;
  const delay = 300;

  useEffect(() => {

    const validateRoute = async () => {
      setLoading(true);
      try {
        settings.lachelaSettings.doc('event').onSnapshot((doc) => {
          if (doc.exists && doc.data().adminGuest) {
            setIsRouteEnabled(true);
          } else {
            setError('Ruta no habilitada.');
          }
        })

      } catch (err) {
        setError('Error al validar la ruta: ' + err);
      }
      setLoading(false);
    };

    validateRoute();
  }, []);

  const handleQrScan = (data) => {
    if (data) {
      setQrData(data);
      searchGuestByQR(data);
    }
  };

  const handleQrError = (err) => {
    setError('Error al escanear el QR: ' + err);
  };

  const searchGuestByEmail = async (identifier) => {

    setLoading(true);
    try {

      const querySnapshot = await database.lachelaCodes.where('email', '==', email).get();

      if (querySnapshot.empty) {
        setError(`No se encontró usuario por email`)
      }
      else {
        const userDoc = querySnapshot.docs[0];
        const userData = userDoc.data();
        setGuestData(userData);
      }
    } catch (err) {
      setError('Error al buscar invitado: ' + err);
    }
    setLoading(false);
  };

  const searchGuestByQR = async (data) => {
    const eventUrl = new URL(data);
    const searchParams = new URLSearchParams(eventUrl.search);
    const proxyKey = searchParams.get('pk');
    setLoading(true);
    setError(null)
    try {
      const querySnapshot = await database.lachelaCodes.doc(proxyKey).get()

      if (!querySnapshot.empty) {
        const user = querySnapshot.data();

        setGuestData(user);
      } else {
        setError('Invitado no encontrado.');
      }
    } catch (err) {
      setError('Error al buscar invitado: ' + err);
    }
    setLoading(false);
  };

  const handleIncrementChange = (value) => {
    if (value >= minIncrement && value <= maxIncrement) {
      setDrinkIncrement(value);
    } else {
      setError(`La cantidad debe estar entre ${minIncrement} y ${maxIncrement}.`);
    }
  };

  const handleUpdateMax = async () => {
    if (guestData && isRouteEnabled) {

      const guestRef = database.lachelaCodes.doc(guestData.id);

      try {
        await guestRef.update({ max: guestData.max + drinkIncrement });
        setSuccess(`La cantidad de bebida se ha incrementado en ${drinkIncrement}.`);
        setError('');
      } catch (err) {
        setError('Error al actualizar el max: ' + err);
      }
    } else {
      setError('Error: La ruta no está habilitada o los datos del invitado no son válidos.');
    }
  };

  const handleUpdateAll = async () => {
    setLoading(true);
    setError('');
    setSuccess('');

    try {

      const querySnapshot = await database.lachelaCodes.where('status', '==', "active").get();

      if (querySnapshot.empty) {
        setError(`No se encontraron usuarios activos.`);
      } else {
        const batch = firebase.firestore().batch();

        querySnapshot.forEach((doc) => {
          const userRef = doc.ref;
          const currentMax = doc.data().max || 0;
          const newMax = currentMax + drinkIncrement;

          batch.update(userRef, { max: newMax });
        });

        await batch.commit();

        setSuccess(`Todos los usuarios activos han sido actualizados con éxito.`);
      }
    } catch (err) {
      setError('Error al actualizar usuarios: ' + err.message);
    }

    setLoading(false);
  };


  return (
    <div className="responsive-container">
      <style>{`
      @media (max-width: 576px) {
        .responsive-container {
          background-image: url('../../../mobilepe.svg');
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
        }
        .card-content {
          padding: 20px;
        }
      }
      @media (min-width: 577px) and (max-width: 1024px) {
        .responsive-container {
          background-image: url('../../../tabletpe.svg');
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
        }
           .card-content {
          padding: 20px;
        }
      }
      @media (min-width: 1025px) {
        .responsive-container {
          background-image: url('../../../backgroundpe.svg');
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
        }
           .card-content {
          padding: 20px;
        }
      }
      .card {
        background-color: rgba(255, 255, 255, 0.9);
        border-radius: 12px;
        box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
      }
      .increment-buttons {
        display: flex;
        justify-content: space-between;
        max-width: 150px;
        margin: 0 auto;
      }
      .increment-buttons button {
        width: 40px;
        height: 40px;
        font-size: 1.25rem;
        color: #fff;
        background-color: #088aff;
        border: none;
        border-radius: 4px;
      }
      .increment-buttons input {
        width: 60px;
        text-align: center;
        font-size: 1rem;
      }
         .card-content {
          padding: 20px;
        }
    `}</style>

      <div className="d-flex align-items-center justify-content-center" style={{ height: '100vh', padding: '30px' }}>
        <Card className="card" style={{ width: '100%', maxWidth: '600px', height: "auto", minHeight: 560 }}>
          <div className="card-content">
            <h3 style={{ color: '#088aff', textAlign: 'center' }}>Admin de Invitados</h3>

            {loading && <Spinner animation="border" variant="primary" className="mt-3" />}
            {error && <Alert variant="danger" className="mt-3">{error}</Alert>}
            {success && <Alert variant="success" className="mt-3">{success}</Alert>}

            {isRouteEnabled && (
              <>
                <Tabs
                  activeKey={activeTab}
                  onSelect={(tab) => {
                    setActiveTab(tab)
                    setGuestData(null)
                  }}
                  className="mb-3"
                >
                  <Tab eventKey="email" title="Buscar por Email">

                    <div className="mt-3">
                      <Form.Group controlId="formEmail">
                        <Form.Label>Buscar por email:</Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="Ingresa el email del invitado"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </Form.Group>

                      <Button variant="primary" style={{ width: "100%", marginTop: 15 }} onClick={() => searchGuestByEmail(email)}>
                        Buscar
                      </Button>

                      {guestData && (
                        <div className="mt-3">
                          <p><strong>Invitado:</strong> {guestData.name}</p>
                          <p><strong>Email:</strong> {guestData.email}</p>
                          <p><strong>Tipo de bebida:</strong> {guestData.drink}</p>
                          <p><strong>Bebidas asignadas:</strong> {guestData.max}</p>
                          <p><strong>Bebidas disponibles:</strong> {guestData.max - guestData.counter}</p>

                          <Form.Group controlId="formIncrement">
                            <Form.Label>Incrementar cantidad de bebida (1-5):</Form.Label>
                            <div className="increment-buttons">
                              <button
                                onClick={() => handleIncrementChange(drinkIncrement - 1)}
                                disabled={drinkIncrement <= minIncrement}
                              >
                                -
                              </button>
                              <input
                                type="number"
                                value={drinkIncrement}
                                onChange={(e) => handleIncrementChange(parseInt(e.target.value))}
                                min={minIncrement}
                                max={maxIncrement}
                              />
                              <button
                                onClick={() => handleIncrementChange(drinkIncrement + 1)}
                                disabled={drinkIncrement >= maxIncrement}
                              >
                                +
                              </button>
                            </div>
                          </Form.Group>

                          <Button variant="primary" style={{ width: "100%", marginTop: 15 }} onClick={handleUpdateMax}>
                            Actualizar Bebida
                          </Button>
                        </div>
                      )}
                    </div>
                  </Tab>

                  <Tab eventKey="qr" title="Buscar por QR">

                    <div className="mt-3" style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                      <h4>Escanear QR del invitado</h4>
                      <QrReader
                        style={{ width: '100%', maxWidth: '300px', height: 'auto' }}
                        delay={delay}
                        onError={handleQrError}
                        onScan={handleQrScan}
                        className="some"
                      />

                      {guestData && (
                        <div className="mt-3">
                          <p><strong>Invitado:</strong> {guestData.name}</p>
                          <p><strong>Email:</strong> {guestData.email}</p>
                          <p><strong>Tipo de bebida:</strong> {guestData.drink}</p>
                          <p><strong>Bebidas asignadas:</strong> {guestData.max}</p>
                          <p><strong>Bebidas disponibles:</strong> {guestData.max - guestData.counter}</p>

                          <Form.Group controlId="formIncrement">
                            <Form.Label>Incrementar cantidad de bebida (1-5):</Form.Label>
                            <div className="increment-buttons">
                              <button
                                onClick={() => handleIncrementChange(drinkIncrement - 1)}
                                disabled={drinkIncrement <= minIncrement}
                              >
                                -
                              </button>
                              <input
                                type="number"
                                value={drinkIncrement}
                                onChange={(e) => handleIncrementChange(parseInt(e.target.value))}
                                min={minIncrement}
                                max={maxIncrement}
                              />
                              <button
                                onClick={() => handleIncrementChange(drinkIncrement + 1)}
                                disabled={drinkIncrement >= maxIncrement}
                              >
                                +
                              </button>
                            </div>
                          </Form.Group>

                          <Button variant="primary" style={{ width: "100%", marginTop: 15 }} onClick={handleUpdateMax}>
                            Actualizar Bebida
                          </Button>
                        </div>
                      )}
                    </div>
                  </Tab>

                  <Tab eventKey="all" title="Actualizar Todos">

                    <div className="mt-3">
                      <Form.Group controlId="formUpdateAll">
                        <Form.Label>Incrementar bebida para todos (1-5):</Form.Label>
                        <div className="increment-buttons">
                          <button
                            onClick={() => handleIncrementChange(drinkIncrement - 1)}
                            disabled={drinkIncrement <= minIncrement}
                          >
                            -
                          </button>
                          <input
                            type="number"
                            value={drinkIncrement}
                            onChange={(e) => handleIncrementChange(parseInt(e.target.value))}
                            min={minIncrement}
                            max={maxIncrement}
                          />
                          <button
                            onClick={() => handleIncrementChange(drinkIncrement + 1)}
                            disabled={drinkIncrement >= maxIncrement}
                          >
                            +
                          </button>
                        </div>
                      </Form.Group>

                      <Button variant="primary" style={{ width: "100%", marginTop: 15 }} onClick={handleUpdateAll}>
                        Actualizar Todos los Usuarios Activos
                      </Button>
                    </div>
                  </Tab>
                </Tabs>
              </>
            )}
          </div>
        </Card>
      </div>
    </div>
  );
};

export default GuestAdmin;
