import React from 'react';
import { Container } from 'react-bootstrap';
import NavBar from '../components/NavBar';

export default function Main() {

  return (
    <div
      className="responsive-container"
      style={{
        height: "100vh",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "30px"
      }}
    >
      <NavBar show={false} color={"#001e37"} />
      <Container fluid className="d-flex flex-column justify-content-center align-items-center" style={{ height: "80vh" }}>
        <div style={{ display: "flex", maxWidth: 500, width: "100%", flexDirection: "column" }}>

        </div>
      </Container>


      <style>{`
        .responsive-container {
          background-image: url('../../../mobilepe.svg');
        }

        @media (min-width: 577px) and (max-width: 1024px) {
          .responsive-container {
            background-image: url('../../../tabletpe.svg');
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
          }
        }

        @media (min-width: 1025px) {
          .responsive-container {
            background-image: url('../../../backgroundpe.svg');
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
          }
        }

        @media (max-width: 576px) {
          .responsive-container {
            background-image: url('../../../mobilepe.svg');
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
          }
        }


        @media (max-width: 576px) {
          .responsive-container {
            padding: 10px;
          }
        }

        @media (min-width: 577px) and (max-width: 1024px) {
          .responsive-container {
            padding: 20px;
          }
        }

        @media (min-width: 1025px) {
          .responsive-container {
            padding: 30px;
          }
        }
      `}</style>
    </div>
  );
}
