import React, { useState } from 'react';
import { Form, Button, Alert, Card, Container } from 'react-bootstrap';
import { database } from '../Firebase';
import QrReader from 'react-qr-reader';

const EventCheckin = () => {
  const [userId, setUserId] = useState(null);
  const [userData, setUserData] = useState(null);
  const [drink, setDrink] = useState('');
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [showCheckinButton, setShowCheckinButton] = useState(false);
  const [showScanner, setShowScanner] = useState(true);

  const delay = 300;

  const handleError = (err) => {
    setError('Error al escanear el QR: ' + err);
  };

  const handleScan = async (data) => {
    if (!data) return;

    const eventUrl = new URL(data);
    const searchParams = new URLSearchParams(eventUrl.search);
    const proxyKey = searchParams.get("pk");

    if (proxyKey) {
      setUserId(proxyKey);
      const userRef = database.lachelaCodes.doc(proxyKey);

      try {
        const doc = await userRef.get();
        if (doc.exists) {
          const userInfo = doc.data();

          if (['registered', 'confirmed'].includes(userInfo.status)) {
            setUserData(userInfo);
            setDrink(userInfo.drink || '');
            setShowCheckinButton(true);
            setShowScanner(false);
          } else if (['active'].includes(userInfo.status)) {
            setSuccessMessage('El QR ya se encuentra activo');
            setShowScanner(true);
          } else {
            setError('Estado no permitido para check-in.');
          }
        } else {
          setError('Usuario no encontrado.');
        }
      } catch (err) {
        setError('Error al obtener los datos del usuario: ' + err);
      }
    }
  };

  const handleDrinkChange = (e) => {
    setDrink(e.target.value);
  };

  const handleCheckin = async () => {
    if (!drink) {
      setError('Por favor selecciona una bebida antes de hacer check-in.');
      return;
    }

    const userRef = database.lachelaCodes.doc(userId);

    try {
      await userRef.update({
        status: 'active',
        drink: drink,
      });
      setSuccessMessage('Check-in exitoso. QR activado para el evento.');
      setUserId(null);
      setUserData(null);
      setDrink('');
      setShowCheckinButton(false);
      setError('');
    } catch (err) {
      setError('Error al hacer check-in: ' + err);
    }
  };

  const handleScanAgain = () => {

    setUserId(null);
    setUserData(null);
    setDrink('');
    setError('');
    setSuccessMessage('');
    setShowCheckinButton(false);
    setShowScanner(true);
  };

  return (
    <Container fluid className="d-flex justify-content-center align-items-center vh-100 bg-cover"
      style={{ backgroundImage: `url('../../../backgroundpe.svg')`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
      <Card style={{ width: '100%', maxWidth: '600px', opacity: 0.9 }} className="shadow-lg">
        <Card.Body className="p-4">
          {!userData && !successMessage &&
            <h3 className="text-center mb-4" style={{ marginTop: 15, marginBottom: 15 }}>
              Escanear QR para Check-in
            </h3>}


          {successMessage && <Alert variant="success" className="text-center">{successMessage}</Alert>}


          {showScanner && !successMessage && (
            <div className="d-flex justify-content-center mb-3">
              <QrReader
                style={{ width: '100%', maxWidth: '300px', height: 'auto' }}
                delay={delay}
                onError={handleError}
                onScan={handleScan}
              />
            </div>
          )}

          {error && <Alert variant="danger" className="text-center">{error}</Alert>}

          {userData && (
            <div className="mt-3 text-center">
              <h4>Información del usuario</h4>
              <p><strong>Nombre:</strong> {userData.name}</p>
              <p><strong>Bebida seleccionada:</strong> {userData.drink || 'No seleccionada'}</p>

              {!userData.drink && (
                <Form.Group controlId="formDrink">
                  <Form.Label>Selecciona tu bebida:</Form.Label>
                  <Form.Control as="select" value={drink} onChange={handleDrinkChange}>
                    <option value="">Selecciona...</option>
                    <option value="Cerveza">Cerveza</option>
                    <option value="Cocktail">Cocktail</option>
                  </Form.Control>
                </Form.Group>
              )}

              {showCheckinButton && (
                <Button variant="primary" className="mt-3" onClick={handleCheckin}>
                  Hacer Check-In
                </Button>
              )}
            </div>
          )}


          {successMessage && (
            <div className="text-center mt-4">
              <Button variant="secondary" onClick={handleScanAgain}>
                Escanear Otro QR
              </Button>
            </div>
          )}
        </Card.Body>
      </Card>
    </Container>
  );
};

export default EventCheckin;
