import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Main from '../pages/Main'
import Redeem from '../pages/Redeem'
import EventCheckin from './EventCheckin'
import EventConfirm from './EventConfirm'
import RedeemStatus from '../pages/RedeemStatus'
import OrderMyGuestDrink from '../pages/OrderMyGuestDrink'
import GuestAdmin from './GuestAdmin'
import GuestReport from './GuestReport'
import AdminReport from '../pages/Report'


export default function Home() {
  return (
    <Router>
      {/* <Context> */}
      <Switch>
        <Route exact path='/' component={Main} />
        <Route exact path="/event-confirm/:email" currentRole="student" component={EventConfirm} />
        <Route exact path="/event-checkin" component={EventCheckin} />
        <Route exact path="/start" currentRole="student" component={OrderMyGuestDrink} />
        <Route exact path="/status/:email" currentRole="student" component={RedeemStatus} />
        <Route exact path="/guest-admin" currentRole="student" component={GuestAdmin} />
        <Route exact path="/guest-report" currentRole="student" component={GuestReport} />
        <Route exact path="/admin-report" currentRole="student" component={AdminReport} />
        {/* <Route exact path="/guest-login" component={GuestLogin}/> */}
        {/* <Route exact path="/host-login" component={HostLogin}/> */}
        {/* <SecureRoute exact path="/make-qr" currentRole="teacher" component={TeacherQRForm}/> */}
        {/* <SecureRoute exact path="/get-details" currentRole="teacher" component={AttendanceDetails}/> */}
        <Route exact path="/scan-qr" currentRole="student" component={Redeem} />
        {/* <Route exact path="/guest" currentRole="teacher" component={Details} /> */}
      </Switch>
      {/* </Context> */}
    </Router>
  )
}